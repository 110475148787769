import React, {useState} from "react";
import TableSearch from "../../../../../base/components/Table/tableSearch";
import Button from "../../../../../base/components/Button";
import {BUTTON_COLORS} from "../../../../../base/components/Button/appearance";
import {useLocationQuery} from "../../../../../base/hooks/useQueryString";
import {Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import {useHighlight} from "../../../../../base/hooks/useHighlight";
import {
    ADMINS_MANAGEMENT_STATUSES,
    ADMINS_MANAGEMENT_STATUSES_NAMES,
} from "../../../../../base/constants/shared";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../base/components/Icon";

export const ADMINS_ACTIONS = {
    DELETE: 'delete',
    RESEND_INVITE: 'resendInvite',
    CHANGE_STATUS: 'changeStatus'
};

export const SearchPlaceholder = () => (
    <>
        No admins found
    </>
);

export const NoAdminsPlaceholder = () => (
    <div className="text-center">
        No admins for now.<br/>
        Click the “Invite admin” option to add a new one.
    </div>
);

const statusesPillClasses = {
    [ADMINS_MANAGEMENT_STATUSES.active]: 'active-pill',
    [ADMINS_MANAGEMENT_STATUSES.invited]:  'deactivated-pill',
    [ADMINS_MANAGEMENT_STATUSES.blocked]: 'blocked-pill'
}

const changeStatusNames = {
    [ADMINS_MANAGEMENT_STATUSES.active]: 'Block',
    [ADMINS_MANAGEMENT_STATUSES.blocked]: 'Unblock'
}

const changeStatusIcon = {
    [ADMINS_MANAGEMENT_STATUSES.active]: 'lock',
    [ADMINS_MANAGEMENT_STATUSES.blocked]: 'unlock'
}

const changeStatusValue = {
    [ADMINS_MANAGEMENT_STATUSES.active]: ADMINS_MANAGEMENT_STATUSES.blocked,
    [ADMINS_MANAGEMENT_STATUSES.blocked]: ADMINS_MANAGEMENT_STATUSES.active
}

const TableFilter = ({ filterProvider }) => {
    const [isOpen, updateIsOpen] = useState(false);

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className=" btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Add filter
                </DropdownToggle>

                <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">
                    Filter
                </DropdownMenu>
            </Dropdown>
        </section>
    )
}

export const TableHeader = ({searchProvider, filterProvider}) => {

    const goToInvite = () => {
        //TODO: Open Invite admin popup
    };

    return (
        <section className="d-flex w-100 pt-1 mb-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search admin..."
                />
            </div>

            {/*<div className="d-flex gap-4">*/}
            {/*    <TableFilter filterProvider={filterProvider} />*/}
            {/*    <Button color={BUTTON_COLORS.primary} onClick={goToInvite} className="d-flex align-items-center">*/}
            {/*        <i className="bx bx-plus me-2"/>*/}
            {/*        Invite admin*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        className: "bg-white",
        Cell: (cellProps) => {
            const {params: {offset = 0}} = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "name",
        className: "bg-white",
        canSort: true,
        Cell: ({row: { original }}) => {
            const {params} = useLocationQuery();
            const {decorateText} = useHighlight(params.search);
            const fullName = `${original?.firstName} ${original?.lastName}`

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(fullName)} className="text-truncate mb-0" style={{maxWidth: "33vw"}}/>
                </section>
            );
        }
    },
    {
        Header: 'Email',
        accessor: 'email',
        className: "bg-white",
        width: 220,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return <div className="table-cell-25 text-truncate" {...decorateText(value)} />;
        },
    },
    {
        Header: 'Role',
        accessor: 'role',
        className: "bg-white",
        canSort: true,
        Cell: ({row: { original }}) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);
            return <div className="table-cell-25 text-truncate" {...decorateText(original?.adminRole?.name)} />;
        },
    },
    {
        Header: 'Status',
        accessor: 'status',
        className: "bg-white",
        canSort: true,
        Cell: ({value}) => {
            return (
                <div className={joinClassNames(statusesPillClasses[value], 'w-fit-content')}>
                    {`${ADMINS_MANAGEMENT_STATUSES_NAMES[value] ?? ''}`}
                </div>
            )
        },
    },
    {
        Header: "Actions",
        className: "bg-white",
        width: 100,
        Cell: ({
                   row: {
                       id: rowId,
                       original: {id, status, email, firstName, lastName, adminRole}
                   },
                   actions
               }) => {

            return <section
                className="d-flex gap-1 align-items-end justify-content-end"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                {/*{status && changeStatusIcon[status] && changeStatusNames[status] && <Icon*/}
                {/*    id={`change-status-${changeStatusNames[status]}-${rowId}`}*/}
                {/*    icon={changeStatusIcon[status]}*/}
                {/*    width={20}*/}
                {/*    height={20}*/}
                {/*    onClick={(event) => {*/}
                {/*        event.stopPropagation();*/}
                {/*        const model = {*/}
                {/*            status: changeStatusValue[status]*/}
                {/*        }*/}
                {/*        actions[ADMINS_ACTIONS.CHANGE_STATUS](id, model);*/}
                {/*    }}*/}
                {/*    className="me-4 cursor-pointer"*/}
                {/*/>}*/}
                {/*{status && changeStatusIcon[status] && changeStatusNames[status] && <UncontrolledTooltip*/}
                {/*    target={`change-status-${changeStatusNames[status]}-${rowId}`}*/}
                {/*    popperClassName={joinClassNames(*/}
                {/*        "tooltip-alternative-name"*/}
                {/*    )}*/}
                {/*    placement="bottom"*/}
                {/*>*/}
                {/*    {changeStatusNames[status]} admin*/}
                {/*</UncontrolledTooltip>}*/}

                {/*{status === ADMINS_MANAGEMENT_STATUSES.invited && <Icon*/}
                {/*    id={`resend-invite-${rowId}`}*/}
                {/*    icon={"envelope"}*/}
                {/*    width={20}*/}
                {/*    height={20}*/}
                {/*    onClick={(event) => {*/}
                {/*        event.stopPropagation();*/}
                {/*        const model = {*/}
                {/*            email,*/}
                {/*            firstName,*/}
                {/*            lastName,*/}
                {/*            roleId: adminRole.id*/}
                {/*        }*/}
                {/*        actions[ADMINS_ACTIONS.RESEND_INVITE](model);*/}
                {/*    }}*/}
                {/*    className="me-4 cursor-pointer"*/}
                {/*/>}*/}
                {/*{status === ADMINS_MANAGEMENT_STATUSES.invited && <UncontrolledTooltip*/}
                {/*    target={`resend-invite-${rowId}`}*/}
                {/*    popperClassName={joinClassNames(*/}
                {/*        "tooltip-alternative-name"*/}
                {/*    )}*/}
                {/*    placement="bottom"*/}
                {/*>*/}
                {/*    Resend invite*/}
                {/*</UncontrolledTooltip>}*/}
                {/*<Icon*/}
                {/*    icon={"trashIcon"}*/}
                {/*    width={20}*/}
                {/*    height={20}*/}
                {/*    onClick={(event) => {*/}
                {/*        event.stopPropagation();*/}
                {/*        actions[ADMINS_ACTIONS.DELETE](id);*/}
                {/*    }}*/}
                {/*    className="me-4 cursor-pointer"*/}
                {/*/>*/}
            </section>;
        }
    }
]